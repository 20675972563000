// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-label {
    font-weight: bold;
}

.other-color{
    /* background-color: #e6e6e6; */
    /* border: 1px;
    border-color: #485c79; */
}

.myButton {
    background-color: #3d6428;
}

.forgotPassword {
    background-color: #485c79;
    border-color: #485c79;

}

.orderLogin {
    border-color: #b1acac;
}

.border-dashed {
    border: 2px dashed #007bff;
    padding: 20px;
    text-align: center;
    cursor: pointer;
  }

  .p-4 {
    padding: 20px;
  }

.mt-1 {
    text-align: center;
  }`, "",{"version":3,"sources":["webpack://./src/components/ordering.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,+BAA+B;IAC/B;4BACwB;AAC5B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;;AAEzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;IAC1B,aAAa;IACb,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,aAAa;EACf;;AAEF;IACI,kBAAkB;EACpB","sourcesContent":[".form-label {\n    font-weight: bold;\n}\n\n.other-color{\n    /* background-color: #e6e6e6; */\n    /* border: 1px;\n    border-color: #485c79; */\n}\n\n.myButton {\n    background-color: #3d6428;\n}\n\n.forgotPassword {\n    background-color: #485c79;\n    border-color: #485c79;\n\n}\n\n.orderLogin {\n    border-color: #b1acac;\n}\n\n.border-dashed {\n    border: 2px dashed #007bff;\n    padding: 20px;\n    text-align: center;\n    cursor: pointer;\n  }\n\n  .p-4 {\n    padding: 20px;\n  }\n\n.mt-1 {\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
