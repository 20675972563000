// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* #2b3824 */

.bg-dark {
    background-color: #3d6428 !important;
    padding-top: 15px;
    padding-bottom: 15px;
}

.nav-link {
    color: rgb(255 255 255 / 98%);
}

.nav-link.active {
    color: #fff; /* Change to your desired color */
    background-color: #333; /* Change to your desired background color */
    /* border-radius: 5px; Optional */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/NavBar/Navbar.css"],"names":[],"mappings":";AACA,YAAY;;AAEZ;IACI,oCAAoC;IACpC,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,WAAW,EAAE,iCAAiC;IAC9C,sBAAsB,EAAE,4CAA4C;IACpE,iCAAiC;EACnC","sourcesContent":["\n/* #2b3824 */\n\n.bg-dark {\n    background-color: #3d6428 !important;\n    padding-top: 15px;\n    padding-bottom: 15px;\n}\n\n.nav-link {\n    color: rgb(255 255 255 / 98%);\n}\n\n.nav-link.active {\n    color: #fff; /* Change to your desired color */\n    background-color: #333; /* Change to your desired background color */\n    /* border-radius: 5px; Optional */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
