// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("IMG_9541.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron-custom {
  width: 100%;
  min-height: 300px;
  padding: 100px 0;
  margin: 0;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) ;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-position: center top 45%; /* Positions the image 25% from the top */
  color: rgb(255, 255, 255);
}

.jumbotron-custom .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

/* Remove any default jumbotron margins */
.jumbotron {
  margin-bottom: 0;
}

.jumbotron-custom .container-fluid {
  text-align: center;
}

.jumbotron {
  margin-bottom: 40px;
  margin-top: 70px;
}`, "",{"version":3,"sources":["webpack://./src/components/Jumbotron/JumbotronComponent.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,gBAAgB;EAChB,SAAS;EACT,0DAAsC;EACtC,kHAA8F;EAC9F,sBAAsB;EACtB,mCAAmC,EAAE,yCAAyC;EAC9E,yBAAyB;AAC3B;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA,yCAAyC;AACzC;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".jumbotron-custom {\n  width: 100%;\n  min-height: 300px;\n  padding: 100px 0;\n  margin: 0;\n  background-image: url('IMG_9541.png') ;\n  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('IMG_9541.png');\n  background-size: cover;\n  background-position: center top 45%; /* Positions the image 25% from the top */\n  color: rgb(255, 255, 255);\n}\n\n.jumbotron-custom .container-fluid {\n  padding-left: 15px;\n  padding-right: 15px;\n}\n\n/* Remove any default jumbotron margins */\n.jumbotron {\n  margin-bottom: 0;\n}\n\n.jumbotron-custom .container-fluid {\n  text-align: center;\n}\n\n.jumbotron {\n  margin-bottom: 40px;\n  margin-top: 70px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
