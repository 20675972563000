// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron-custom {
    width: 100%;
    min-height: 300px;
    padding: 100px 0;
    margin: 0;
    background-color: #a8cfa3fc;
    color: rgb(5, 21, 5);

  }
  
  .jumbotron-custom .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    
  }
  
  /* Remove any default jumbotron margins */
  .jumbotron {
    margin-bottom: 0;
  }

  .jumbotron-custom .container-fluid {
    text-align: center;
  }

  .jumbotron {
    margin-bottom: 40px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/Jumbotron/JumbotronComponent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;IACT,2BAA2B;IAC3B,oBAAoB;;EAEtB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;;EAErB;;EAEA,yCAAyC;EACzC;IACE,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".jumbotron-custom {\n    width: 100%;\n    min-height: 300px;\n    padding: 100px 0;\n    margin: 0;\n    background-color: #a8cfa3fc;\n    color: rgb(5, 21, 5);\n\n  }\n  \n  .jumbotron-custom .container-fluid {\n    padding-left: 15px;\n    padding-right: 15px;\n    \n  }\n  \n  /* Remove any default jumbotron margins */\n  .jumbotron {\n    margin-bottom: 0;\n  }\n\n  .jumbotron-custom .container-fluid {\n    text-align: center;\n  }\n\n  .jumbotron {\n    margin-bottom: 40px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
